<template>
  <div class="domain-detail-dns">
    <page-block v-if="hasToolNew" :title="$t('add.title')">
      <transition name="fade" mode="out-in">
        <base-loader v-if="addIsLoading" />
        <main-card v-else-if="config">
          <base-stack-collapser :more="$t('add.collapse')">
            <div>
              <order-config
                :period="12"
                :configurator="config"
                @init="onAddInit"
                @ready="onAddReady(true, $event)"
                @notready="onAddReady(false, $event)"
                @change="onAddChange"
              />
              <!--                slot="footerEnd"-->
              <base-button
                :disabled="!addIsValid"
                :loading="addIsSending"
                class="domain-detail-dns__btn"
                @click="sendNew"
              >
                {{ $t('add.btn') }}
              </base-button>
            </div>
          </base-stack-collapser>
        </main-card>
      </transition>
    </page-block>
    <page-block
      :title="$t('list.title')"
      :style="{ width: tableWidth ? tableWidth + 'px' : '100%' }"
      class="domain-detail-dns__block"
    >
      <transition name="fade" mode="out-in">
        <base-loader v-if="isLoading" />
        <base-table
          v-else-if="sortedList.length"
          :list="sortedList"
          :columns="tableHead"
          :sort="sort"
          class="domain-detail-dns__table standart-text"
          @sort-change="onSortChange"
        >
          <dns-record-table-row
            v-for="(item, i) in sortedList"
            :key="i"
            :item="item"
            :columns="tableHead"
          />
        </base-table>
        <base-empty
          v-else-if="sortedList.length === 0 && tariff.dtype === 'slave'"
          :title="$t('empty.title')"
          :text="$t('empty.text')"
          class="ssl-info__empty"
        />
      </transition>
    </page-block>
  </div>
</template>

<script>
import BaseTable from '@/components/Table/BaseTable.vue';
import OrderConfig from '@/components/Configurator/OrderConfig.vue';
import DnsRecordTableRow from '../../../components/DnsRecordTableRow.vue';
import { DnsHost } from '@/models/DnsMgr/DnsHost';
import BaseConfigurator from '@/models/base/BaseConfigurator';
import showErrorModal from '@/mixins/showErrorModal';
import BaseStackCollapser from '@/components/BaseCollapser/BaseStackCollapser.vue';
import BaseEmpty from '@/components/BaseEmpty/BaseEmpty.vue';
export default {
  name: 'DomainDetailDns',
  components: {
    BaseEmpty,
    BaseTable,
    DnsRecordTableRow,
    BaseStackCollapser,
    OrderConfig,
  },
  mixins: [showErrorModal],
  props: {
    tariff: {
      type: DnsHost,
      required: true,
      validator: obj => obj instanceof DnsHost,
    },
    moduleMain: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      list: [],
      tableHeadMain: [
        {
          key: 'type',
          label: this.$t('list.tableHead.type'),
          style: {
            width: '80px',
          },
          sort: {
            prop: 'rtype_orig',
            order: 'asc',
          },
        },
        {
          key: 'host',
          label: this.$t('list.tableHead.host'),
          style: {
            flex: '1 1 300px',
            width: '300px',
            minWidth: '300px',
          },
          sort: {
            prop: 'name',
            order: 'asc',
          },
        },
        {
          key: 'value',
          label: this.$t('list.tableHead.value'),
          style: {
            width: '290px',
            maxWidth: '290px',
          },
          sort: {
            prop: 'value',
            order: 'asc',
          },
        },
        {
          key: 'ttl',
          label: this.$t('list.tableHead.ttl'),
          style: {
            width: '70px',
          },
          sort: {
            prop: 'ttl',
            order: 'asc',
          },
        },
        {
          key: 'info',
          label: this.$t('list.tableHead.info'),
          style: {
            width: '250px',
            maxWidth: '250px',
          },
        },
      ],
      tableHeadMore: [
        {
          key: 'more',
          style: {
            width: '44px',
          },
        },
      ],
      tableWidth: 0,
      resizeObserver: null,
      config: null,
      addFormData: {},
      addIsValid: false,
      addIsSending: false,
      addIsLoading: false,
    };
  },
  computed: {
    current() {
      return this.tariff;
    },
    moduleState() {
      const splitted = this.moduleMain.split('.');
      const state = splitted.reduce((acc, item) => {
        return acc[item];
      }, this.$store.state);
      return state;
    },
    modulePath() {
      return this.moduleMain.replaceAll('.', '/');
    },
    tools() {
      return this.moduleState.tools;
    },
    dnsMgrTools() {
      return this.moduleState.dnsMgrTools;
    },
    toolRecord() {
      return this.dnsMgrTools.record;
    },
    sort() {
      return this.moduleState.moduleDnsRecords.sort;
    },
    sortedList() {
      return this.$store.getters[`${this.modulePath}/moduleDnsRecords/GET_SORTED_LIST`];
    },
    isLoading() {
      return this.moduleState.moduleDnsRecords.isLoading;
    },
    recordTools() {
      return this.moduleState.moduleDnsRecords.tools;
    },
    tableHead() {
      const arr = [...this.tableHeadMain];
      if (this.recordTools.edit || this.recordTools.delete) arr.push(...this.tableHeadMore);
      return arr;
    },
    toolNew() {
      return this.recordTools.new;
    },
    hasToolNew() {
      return this.toolNew && this.toolNew.isEnable(this.current);
    },
  },
  watch: {
    toolRecord(tool) {
      if (tool && tool.isEnable(this.current)) this.fetchList();
    },
    toolNew(tool) {
      if (tool && tool.isEnable(this.current)) this.fetchNew();
    },
    // 'addFormData.value': function (event) {
    //   if (this.addFormData && this.addFormData.rtype === 'txt') {
    //     // console.log(event);
    //     this.addFormData.value.replaceAll('\n', '');
    //     console.log(this.addFormData.value);
    //   }
    // },
  },
  mounted() {
    this.resizeObserver = new ResizeObserver(entries => {
      this.setTableWidth(entries[0].contentRect.width);
    });
    this.resizeObserver.observe(this.$el.offsetParent);
    this.updateSort(this.sort);
    if (this.dnsMgrTools) {
      if (this.toolNew && this.toolNew.isEnable(this.current)) this.fetchNew();
      if (this.toolRecord && this.toolRecord.isEnable(this.current)) this.fetchList();
    }
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.$el.offsetParent);
      this.resizeObserver = null;
    }
  },
  methods: {
    fetchList(update = false) {
      const action = update ? 'updateList' : 'fetchList';
      const params = {
        func: this.toolRecord.func,
        elid: this.current.domain,
        auth: this.current.auth,
      };
      return this.$store.dispatch(`${this.modulePath}/moduleDnsRecords/${action}`, params);
    },
    fetchNew() {
      this.addIsLoading = true;
      const params = {
        func: this.toolNew.func,
        plid: this.current.elid,
        auth: this.current.auth,
      };
      return this.$store
        .dispatch(`${this.modulePath}/moduleDnsRecords/fetchDnsMgrToolAction`, params)
        .then(data => {
          const { fields, model, hidefields, slist } = data;
          this.config = new BaseConfigurator({
            customfields: fields,
            model,
            slist,
            hidefields,
          });
        })
        .finally(() => (this.addIsLoading = false));
    },
    sendNew() {
      this.addIsSending = true;
      if (this.addFormData && this.addFormData.rtype === 'txt') {
        //     // console.log(event);
        this.addFormData.value = this.addFormData.value.replaceAll('\n', '');
        // console.log(this.addFormData.value.replaceAll('\n', ''));
      }

      const params = {
        func: this.toolNew.func,
        plid: this.current.domain,
        auth: this.current.auth,
        ...this.addFormData,
      };
      this.$store
        .dispatch(`${this.modulePath}/moduleDnsRecords/sendDnsMgrToolAction`, params)
        .then(data => {
          if (data.ok) {
            const props = {
              title: this.$t('add.success.title'),
              text: this.$t('add.success.text'),
            };
            this.showResult(props);
            Promise.all([this.fetchNew(), this.fetchList(true)]);
          }
        })
        .catch(e => this.showError(e))
        .finally(() => {
          this.addIsSending = false;
          this.addIsValid = false;
        });
    },
    onSortChange(payload) {
      let { prop, order } = payload;
      if (this.sort.prop === prop) order = order === 'asc' ? 'desc' : 'asc';
      this.$store.commit(`${this.modulePath}/moduleDnsRecords/SET_SORT`, { prop, order });
    },
    updateSort(sort) {
      const col = this.tableHead.find(i => i.sort && i.sort.prop === sort.prop);
      col.sort.order = sort.order;
    },
    setTableWidth(value) {
      this.tableWidth = value;
    },
    onAddInit(data) {
      this.addFormData = data;
    },
    onAddReady(isValid, data) {
      this.addIsValid = isValid;
      this.addFormData = { ...data };
    },
    onAddChange(data) {
      this.addFormData = { ...data };
    },
    submitAdd() {
      // console.log(this.addFormData);
    },
    showResult(props) {
      this.$modals.open({
        name: 'ResultModal',
        footer: {
          centered: true,
          confirm: {
            props: {
              title: this.$t('good'),
            },
            on: {
              click: () => {
                this.$modals.close();
              },
            },
          },
        },
        ...props,
      });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "add": {
      "title": "Добавить запись",
      "btn": "Добавить запись",
      "collapse": "Нажмите, чтобы раскрыть",
      "success": {
        "title": "Успешно",
        "text": "Запись успешно добавлена"
      }
    },
    "empty": {
      "title": "Нет DNS записей",
      "text": "Данные для slave домена до сих пор не загружены с DNS мастера"
    },
    "list": {
      "title": "DNS записи",
      "tableHead": {
        "type": "Тип",
        "host": "Хост",
        "value": "Значение",
        "ttl": "TTL",
        "info": "Дополнительно"
      }
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
.domain-detail-dns {
  padding-top 1rem;
  &__table {
    &-value {
      word-break: break-all;
    }
  }
  //&__block {
  //  padding-top 4rem;
  //}
  &__btn {
    margin-top: 1.25rem;
    float: right;
  }
}
</style>
