<template>
  <div :class="classes" class="base-collapser">
    <!--    <div :open="isOpen" class="base-collapser__details" @click="toggle">-->
    <div :open="isOpen" class="base-collapser__details">
      <div class="base-collapser__summary standart-text">
        <div class="base-collapser__summary-content">
          <div class="base-collapser__summary-start" @click="toggle">
            <slot name="summary">
              <div class="primary-color">
                {{ toggledSummaryText }}
              </div>
            </slot>
          </div>
          <div
            v-if="$slots.summaryEnd || (summary && summary.end)"
            class="base-collapser__summary-end"
          >
            <slot name="summaryEnd">
              {{ summary && summary.end ? summary.end : '' }}
            </slot>
          </div>
        </div>
        <div v-if="iconName" class="base-collapser__icon primary-color" @click="toggle">
          <svg-icon :name="iconName" />
        </div>
      </div>
      <transition name="fade">
        <section v-if="hasContent" v-show="isOpen" class="base-collapser__content standart-text">
          <slot />
        </section>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseStackCollapser',
  props: {
    theme: {
      type: String,
      default: 'default',
      validator: val => ['default', 'plain'].includes(val),
    },
    open: {
      type: Boolean,
      default: false,
    },
    more: {
      type: String,
      default: 'Подробнее',
    },
    less: {
      type: String,
      default: 'Скрыть',
    },
    summary: {
      type: [String, Object],
      default: null,
      validator: val =>
        val || !val || (typeof val === 'object' && (val.more || val.less || val.end)),
    },
    icon: {
      type: [String, Object, Boolean],
      default: () => ({ more: 'arrow-down', less: 'arrow-up' }),
      validator: val => !val || val || (typeof val === 'object' && val.more && val.less),
    },
    reverse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: this.open,
    };
  },
  computed: {
    hasContent() {
      return !!this.$slots.default;
    },
    classes() {
      const base = 'base-collapser';
      return {
        [`${base}--${this.theme}`]: !!this.theme,
        [`${base}--open`]: this.isOpen,
        [`${base}--reverse`]: this.reverse,
        [`${base}--empty`]: !this.hasContent,
      };
    },
    iconName() {
      if (!this.icon) return null;
      const moreIcon = this.icon && this.icon.more ? this.icon.more : this.icon;
      const lessIcon = this.icon && this.icon.less ? this.icon.less : this.icon;
      return this.isOpen ? lessIcon : moreIcon;
    },
    toggledSummaryText() {
      // let moreText = this.$t('more');
      let moreText = this.more;
      if (this.summary && typeof this.summary === 'string') moreText = this.summary;
      else if (this.summary && this.summary.more) moreText = this.summary.more;
      // let lessText = this.$t('less');
      let lessText = this.less;
      if (this.summary && typeof this.summary === 'string') lessText = this.summary;
      else if (this.summary && this.summary.less) lessText = this.summary.less;
      return this.isOpen ? lessText : moreText;
    },
  },
  methods: {
    toggle() {
      if (!this.hasContent) return;
      this.isOpen = !this.isOpen;
      this.$emit('open:update', this.isOpen);
      this.$emit('change', this.isOpen);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "more": "Подробнее",
    "less": "Скрыть"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.base-collapser {

  &__details {

    &::-webkit-details-marker {
      display: none;
    }

    ^[0]--default & {
      flexy(flex-start, stretch, nowrap, column);
      margin: 0 -0.5rem;
    }
    ^[0]--plain & {
      flexyi(flex-start, flex-start, nowrap, column);
      margin: -0.25rem;
    }
  }

  &__summary {
    cursor: pointer;
    user-select: none;
    order: 0;

    ^[0]--empty & {
      cursor: auto;
      pointer-events: none;
    }

    ^[0]--reverse & {
      order: 2;
    }

    ^[0]--default & {
      flexy(space-between, flex-start);
      padding: ($gutter-vertical / 2) 0.5rem;
    }
    ^[0]--plain & {
      flexyi(flex-start, center);
      padding: 0.25rem;
      //border-bottom: 1px dashed var(--separator);
    }

    &-content {
      flex: 1 1 auto;
      +breakpoint(sm-and-up) {
        line-height: 1;
        flexy(space-between, baseline);
      }
    }

    &-end {
      margin-top: 0.5rem;

      ^[0]--default & {
        +breakpoint(sm-and-up) {
          margin: 0 1rem;
        }
      }
    }
  }

  &__content {
    ^[0]--default & {
      padding: 0.5rem;
    }
    ^[0]--plain & {
      padding: 0.25rem;
    }
  }

  &__icon {
    font-size: 1.5em;
    line-height: 1;
    height: 1em;
    margin-left: 0.5rem;

    ^[0]--empty & {
      visibility: hidden;
    }
  }

  &__content {
    //transition: height 0.5s;
  }
}
</style>
